import { useTranslation } from "react-i18next";
import { Dropdown, Skeleton } from "antd";
import { useTypedSelector } from "../lib/state/store";
import Image from "next/image";
import {
  useMediaQueryList,
  useMediaQueryListSize,
} from "../lib/hooks/media.query";
import FlatDropDown from "./flat.dropdown";

function LanguageSwitcher() {
  const { isNonDesktop } = useMediaQueryList();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const languages = useTypedSelector((state) => state.lang).languages;
  const selectedLanguage = languages.find(
    (w) => w.lang === i18n.language
  )?.name;

  // const items: MenuProps["items"] = languages
  const items = languages
    .filter((l) => l.status === "1")
    .filter((l) => l.name !== selectedLanguage)
    .sort((a, b) => (a.lang < b.lang ? 1 : -1))
    .map((language) => ({
      key: language.lang,
      label: (
        <a
          target="self"
          rel="noopener noreferrer"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            changeLanguage(language.lang);
          }}
          className="a-nohover topnav-text-link dropdown-menuitem-content-height"
        >
          {language.name}
        </a>
      ),
    }));

  const skeletoncss = useMediaQueryListSize([
    "!min-w-[60px] !max-w-[60px] !h-[16px]",
    "!min-w-[60px] !max-w-[60px] !h-[16px]",
    "!min-w-[70px] !max-w-[70px] !h-[18px]",
    "!min-w-[70px] !max-w-[70px] !h-[18px]",
    "!min-w-[70px] !max-w-[70px] !h-[22px]",
  ]);

  const ml = useMediaQueryListSize([
    "ml-[10px]",
    "ml-[7px]",
    "ml-[7px]",
    "ml-[7px]",
    "ml-[7px]",
  ]);

  return languages && languages.length == 0 ? (
    <div className="topnav-text-link button-padding">
      <Skeleton.Input active className={`flex-shrink-0 ${skeletoncss}`} />
    </div>
  ) : !isNonDesktop ? (
    <FlatDropDown items={items}>
      <a
        onClick={(e) => e.preventDefault()}
        className="pointer flex items-end justify-between space-x-1  topnav-text-link"
      >
        <span className="flex items-center">{selectedLanguage}</span>
        <span className={`flex items-center self-stretch flex-shrink-0 ${ml}`}>
          <Image
            src="/arrow-down.png"
            alt="arrow-down"
            width="12"
            height="12"
            className="arrow-img"
          />
        </span>
      </a>
    </FlatDropDown>
  ) : (
    <Dropdown menu={{ items }}>
      <a
        onClick={(e) => e.preventDefault()}
        className="pointer flex items-end justify-between footer-nav-text-link button-padding"
      >
        <span className="flex items-center">{selectedLanguage}</span>
        <span className={`flex items-center self-stretch flex-shrink-0 ${ml}`}>
          <Image
            src="/arrow-down.png"
            alt="arrow-down"
            width="12"
            height="12"
            className="arrow-img"
          />
        </span>
      </a>
    </Dropdown>
  );
}

export default LanguageSwitcher;
