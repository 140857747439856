import { useMediaQuery } from "react-responsive";

export function useMediaQueryList() {
  const isDesktopWide = useMediaQuery({ minWidth: 1919.001 });
  const isDesktop = useMediaQuery({ minWidth: 1366, maxWidth: 1919 });
  const isTabletWide = useMediaQuery({
    minWidth: 1023.01,
    maxWidth: 1365.9999,
  });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isMobile = useMediaQuery({ maxWidth: 767.9999 });
  const isNonDesktop = isMobile || isTablet || isTabletWide;
  return {
    isDesktop,
    isNonDesktop,
    isTabletWide,
    isTablet,
    isMobile,
    isDesktopWide,
  };
}

export function useMediaQueryListSize<type>(sizeArr: type[]) {
  const { isMobile, isTablet, isTabletWide, isDesktop, isDesktopWide } =
    useMediaQueryList();
  const width = isMobile
    ? sizeArr[0]
    : isTablet
    ? sizeArr[1]
    : isTabletWide
    ? sizeArr[2]
    : isDesktop
    ? sizeArr[3]
    : isDesktopWide
    ? sizeArr[4]
    : sizeArr[4];
  return width;
}
