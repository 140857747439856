import { apiClient } from "../util/http";
import { nestedObjectToQueryString } from "../util/url";

class User {
  private userUrl: string = "/web/user";

  async registerCompanyUser(
    companyInfo: RegisterCompanyUserType
  ): Promise<InterfaceBasicResponseDataType> {
    try {
      const response = await apiClient.post(
        this.userUrl + "/register/company",
        companyInfo
      );
      return response;
    } catch (error) {
      console.error("注册企业用户失败", error);
      throw error;
    }
  }

  // 注册个人用户
  registerIndividualUser = async (
    userInfo: RegisterIndividualUserType
  ): Promise<InterfaceBasicResponseDataType> => {
    try {
      const response = await apiClient.post(
        this.userUrl + "/register/individual",
        userInfo
      );
      return response;
    } catch (error) {
      console.error("注册个人用户失败", error);
      throw error;
    }
  };

  // 用户登录
  login = async (
    loginInfo: FormData
  ): Promise<InterfaceBasicResponseDataType & { token: string }> => {
    try {
      const response = await apiClient.post("/login", loginInfo, true);
      return response;
    } catch (error) {
      console.error("用户登录失败", error);
      throw error;
    }
  };

  // 用户登出
  logout = async (): Promise<InterfaceBasicResponseDataType> => {
    try {
      const response = await apiClient.post("/logout", {});
      return response;
    } catch (error) {
      console.error("用户登出失败", error);
      throw error;
    }
  };

  // TODO 更换接口获取srvUser中的信息
  getUserProfile = async (): Promise<
    InterfaceBasicResponseDataType & {
      data: UserProfile;
    }
  > => {
    try {
      const response = await apiClient.get("/system/user/profile");
      console.log("user profile", response);
      return response;
    } catch (error) {
      console.error("获取用户信息失败", error);
      throw error;
    }
  };

  // 获取客户资料
  getCustomerProfile = async (): Promise<
    InterfaceBasicResponseDataType & {
      data: Array<Array<SysUserProfileType | CustomerProfileType>>;
    }
  > => {
    try {
      const response = await apiClient.get("/web/user/customerprofile");
      return response;
    } catch (error) {
      console.error("获取客户信息失败", error);
      throw error;
    }
  };

  // 更新用户头像
  updateAvatar = async (
    formData: FormData
  ): Promise<
    InterfaceBasicResponseDataType & {
      imgUrl: string;
      msg: string;
    }
  > => {
    try {
      const response = await apiClient.post(
        "/system/user/profile/avatar",
        formData,
        true
      );
      return response;
    } catch (error) {
      console.error("更新头像失败", error);
      throw error;
    }
  };

  // 更新用户资料
  updateSysUserProfile = async (data: {
    nickName?: string;
    phonenumber?: string;
    email?: string;
  }): Promise<InterfaceBasicResponseDataType> => {
    try {
      const response = await apiClient.put("/web/user/profile", data);
      return response;
    } catch (error) {
      console.error("更新用户信息失败", error);
      throw error;
    }
  };

  // 修改密码
  updateUserPwd = async (
    oldPassword: string,
    newPassword: string
  ): Promise<InterfaceBasicResponseDataType> => {
    const data = {
      oldPassword,
      newPassword,
    };
    try {
      const response = await apiClient.put(
        "/system/user/profile/updatePwd?" + nestedObjectToQueryString(data),
        data
      );
      return response;
    } catch (error) {
      console.error("更新用户密码失败", error);
      throw error;
    }
  };
}

export const userApi = new User();
