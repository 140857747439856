"use client";

import "./header.css";
import Link from "next/link";
import { Badge, Carousel, Dropdown, Typography } from "antd";

import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./language.switcher";
import CurrencyExchange from "./currency.exchange";
import { StaticLinks } from "../lib/route/link";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { syncDataToAllBrowserTabs, useTypedSelector } from "../lib/state/store";
import { userApi } from "../lib/api/user";
import { useDispatch } from "react-redux";
import { setToken, setUserProfile } from "../lib/state/user.slice";
import { usePathname, useRouter } from "next/navigation";
import { apiClient } from "../lib/util/http";
import { useWebSocketMessages } from "../lib/ws/useWebsocketMessages";
import { WebSocketContext } from "../lib/ws/websocket.context";
import {
  useMediaQueryList,
  useMediaQueryListSize,
} from "../lib/hooks/media.query";
import Image from "next/image";
import SearchBar from "./search.bar";
import FlatDropDown from "./flat.dropdown";
import { DownOutlined, MoreOutlined } from "@ant-design/icons";
import React from "react";
import { PriceQuantity } from "../offer/[offerId]/components/price.quantify";
import { OfferPricesContext } from "../lib/context/offer.prices";

function TopNav() {
  const { isNonDesktop, isMobile, isTablet, isTabletWide } =
    useMediaQueryList();
  const router = useRouter();
  const dispatch = useDispatch();
  const pathname = usePathname();
  const { t } = useTranslation("translation");
  const user = useTypedSelector((state) => state.user);
  const [cartNumber, setCartNumber] = useState(0);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const { message } = useWebSocketMessages();
  const { closeWs } = useContext(WebSocketContext);

  useEffect(() => {
    console.log("messgaes from websocket", message);
    const item = message;
    if (item?.cart_info && Array.isArray(item?.cart_info)) {
      const n = item.cart_info.length;
      setCartNumber(n);
    } else {
      setCartNumber(0);
    }
  }, [message]);

  // 退出
  const logOut = useCallback(async () => {
    await userApi.logout();
    dispatch(setUserProfile(null));
    dispatch(setToken({ token: "" }));
    apiClient.setToken("");
    syncDataToAllBrowserTabs();
    // 关闭websocket连接
    closeWs();
    router.push(StaticLinks.Login);
  }, [closeWs, dispatch, router]);

  const userItems = useMemo(() => {
    return {
      loggedIn: [
        {
          key: "shoppingcart",
          label: (
            <Link
              href={StaticLinks.ShoppingCart}
              className="a-nohover topnav-text-link dropdown-menuitem-content-height"
            >
              <div className="flex flex-nowrap item-center first-letter:uppercase">
                {t("shoppingCart")}
              </div>
            </Link>
          ),
        },
        {
          key: "myorders",
          label: (
            <Link
              href={StaticLinks.Order}
              className="a-nohover topnav-text-link dropdown-menuitem-content-height"
            >
              <div className="flex flex-nowrap item-center first-letter:uppercase">
                {t("my_orders")}
              </div>
            </Link>
          ),
        },
        {
          key: "setting",
          label: (
            <Link
              href={StaticLinks.AccountSetting}
              className="a-nohover topnav-text-link dropdown-menuitem-content-height"
            >
              <div className="flex flex-nowrap item-center first-letter:uppercase">
                {t("user.account.setting")}
              </div>
            </Link>
          ),
        },
        {
          key: "logout",
          label: (
            <Link
              href=""
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                logOut();
              }}
              className="a-nohover topnav-text-link dropdown-menuitem-content-height"
            >
              <div className="flex flex-nowrap item-center first-letter:uppercase">
                {t("logout")}
              </div>
            </Link>
          ),
        },
      ],
      loggedout: [
        {
          key: "Register",
          label: (
            <Link
              href={StaticLinks.Register}
              className="a-nohover topnav-text-link dropdown-menuitem-content-height"
            >
              <div className="flex flex-nowrap item-center first-letter:uppercase">
                {t("register")}
              </div>
            </Link>
          ),
        },
      ],
    };
  }, [logOut, t]);

  const ml = useMediaQueryListSize([
    "ml-[10px]",
    "ml-[7px]",
    "ml-[7px]",
    "ml-[7px]",
    "ml-[7px]",
  ]);

  return (
    <>
      <div className="logo flex-shrink-0">
        <Link href="/" className="a-nohover">
          <Image
            src={"/logo.png"}
            alt="Logo"
            width="148"
            height="56"
            style={{
              width: isMobile
                ? "80px"
                : isTablet || isTabletWide
                ? "100px"
                : "148px",
              paddingTop: "12px",
              paddingBottom: isNonDesktop ? "0px" : "12px",
            }}
          />
        </Link>
      </div>
      <div className="flex-grow"></div>

      {!isNonDesktop && showSearchBar && <SearchBar />}
      {!isNonDesktop && !showSearchBar && (
        <>
          <Link
            href={StaticLinks.DocPayment}
            className="topnav-text-link link-padding"
          >
            {t("payment")}
          </Link>
          <Link
            href={StaticLinks.DocCustom}
            className="topnav-text-link link-padding"
          >
            {t("custom")}
          </Link>
          <Link
            href={StaticLinks.DocShipping}
            className="topnav-text-link link-padding"
          >
            {t("shipping")}
          </Link>
          <Link
            href={StaticLinks.DocSupport}
            className="topnav-text-link link-padding"
          >
            {t("support")}
          </Link>
        </>
      )}

      {(isTablet || isTabletWide) && (
        <Dropdown
          menu={{
            items: user?.user ? userItems.loggedIn : userItems.loggedout,
          }}
        >
          <div className="flex items-start">
            <div className="flex flex-col items-center justify-end flex-shrink-0">
              <span className="flex items-center justify-between footer-nav-text-link button-padding">
                {user.user?.userName || t("userService")}
                <span className={`flex items-center ${ml}`}>
                  <Image
                    src="/arrow-down.png"
                    alt="arrow-down"
                    width="12"
                    height="12"
                    className="arrow-img"
                  />
                </span>
              </span>
            </div>
          </div>
        </Dropdown>
      )}
      {!showSearchBar && (
        <>
          <CurrencyExchange />
          <LanguageSwitcher />
        </>
      )}
      {!isNonDesktop && (
        <Link
          href="#"
          onClick={() => setShowSearchBar(!showSearchBar)}
          className="topnav-text-link button-padding flex-shrink-0"
        >
          <div className="flex flex-col items-center justify-end">
            <Image
              src="/search-s.png"
              width="36"
              height="36"
              alt="hot"
              style={{ padding: "2px" }}
            ></Image>
            <span>{t("search")}</span>
          </div>
        </Link>
      )}

      {!isNonDesktop && (
        <FlatDropDown
          items={user?.user ? userItems.loggedIn : userItems.loggedout}
        >
          <Link
            href={
              isNonDesktop || user?.user
                ? "#"
                : `${
                    pathname.includes(StaticLinks.Login)
                      ? StaticLinks.Login
                      : StaticLinks.LoginWithRedirect + pathname
                  }`
            }
            className="topnav-text-link flex-shrink-0"
          >
            <div className="flex items-start">
              <div className="flex items-end justify-between flex-shrink-0 flex-grow">
                <div className="flex flex-col items-center justify-between">
                  <Image
                    src="/user.png"
                    width="36"
                    height="36"
                    alt="login"
                    style={{ padding: "2px" }}
                  ></Image>
                  {user.user?.userName || t("login")}
                </div>
                <span className="flex items-end ml-1">
                  <Image
                    src="/arrow-down.png"
                    alt="arrow-down"
                    width="12"
                    height="12"
                    className="arrow-img mb-[6px]"
                  />
                </span>
              </div>
            </div>
          </Link>
        </FlatDropDown>
      )}
      {isMobile && (
        <Dropdown
          menu={{
            items: user?.user ? userItems.loggedIn : userItems.loggedout,
          }}
          placement="bottomRight"
          className="button-padding"
        >
          <MoreOutlined />
        </Dropdown>
      )}
      {!isNonDesktop && (
        <Link
          href={StaticLinks.TopRank}
          className="topnav-text-link button-padding flex-shrink-0"
        >
          <div className="flex flex-col items-center justify-end">
            <Image src="/fire.png" width="36" height="36" alt="hot"></Image>
            <span>{t("hot")}</span>
          </div>
        </Link>
      )}

      {!isNonDesktop && (
        <Link
          href={`${StaticLinks.ShoppingCart}`}
          title={t("shoppingCart")}
          className="flex items-center topnav-text-link button-padding flex-shrink-0"
        >
          <div className="flex flex-col items-center justify-end">
            <Badge count={cartNumber} overflowCount={999} size="default">
              <Image src="/cart.png" width="36" height="36" alt="cart"></Image>
            </Badge>
            {t("shoppingCart")}
          </div>
        </Link>
      )}
    </>
  );
}

export function TopNavShoppingCartCount() {
  const { isNonDesktop, isMobile, isTablet, isTabletWide } =
    useMediaQueryList();
  const { t } = useTranslation("translation");
  const [cartNumber, setCartNumber] = useState(0);
  const { message } = useWebSocketMessages();

  const { value: offerPrices } = useContext(OfferPricesContext);
  const pathname = usePathname();

  useEffect(() => {
    console.log("messgaes from websocket", message);
    const item = message;
    if (item?.cart_info && Array.isArray(item?.cart_info)) {
      const n = item.cart_info.length;
      setCartNumber(n);
    } else {
      setCartNumber(0);
    }
  }, [message]);

  return (
    <>
      {!isMobile && (
        <>
          <div className="logo flex-shrink-0">
            <Link href="/" className="a-nohover">
              <Image
                src={"/logo.png"}
                alt="Logo"
                width="148"
                height="56"
                style={{
                  width: isMobile
                    ? "80px"
                    : isTablet || isTabletWide
                    ? "100px"
                    : "148px",
                  paddingTop: "12px",
                  paddingBottom: isNonDesktop ? "0px" : "12px",
                }}
              />
            </Link>
          </div>
          <div className="flex-grow"></div>
        </>
      )}
      <Carousel
        dots={false}
        infinite={false}
        className="max-w-[90vw] flex-grow"
      >
        {offerPrices.offerId &&
          pathname.includes(`${offerPrices.offerId}`) &&
          offerPrices.offerData &&
          offerPrices.languageItem && (
            <PriceQuantity
              offerData={offerPrices.offerData}
              offerId={`${offerPrices.offerId}`}
              languageItem={offerPrices.languageItem}
              defaultCurrency={offerPrices.defaultCurrency || ""}
              align="row"
            />
          )}
      </Carousel>
      {!isNonDesktop && (
        <Link
          href={`${StaticLinks.ShoppingCart}`}
          title={t("shoppingCart")}
          className="flex items-center topnav-text-link button-padding flex-shrink-0"
        >
          <div className="flex flex-col items-center justify-end">
            <Badge count={cartNumber} overflowCount={999} size="default">
              <Image src="/cart.png" width="36" height="36" alt="cart"></Image>
            </Badge>
            {t("shoppingCart")}
          </div>
        </Link>
      )}
    </>
  );
}

export default React.memo(TopNav);
