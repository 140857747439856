"use client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StaticLinks } from "../lib/route/link";
import "./search.history.css";
import { CloseOutlined } from "@ant-design/icons";

type SearchHistoryProps = {
  recentSearches: string[];
  topSearches: string[];
  // 通知父组件点击事件，比如用于保持下拉框打开状态
  onClick: () => void;
  handleDeleteSearchRecord: (searchTerm: string) => Promise<void>;
};
const SearchHistory = ({
  topSearches,
  recentSearches,
  onClick,
  handleDeleteSearchRecord,
}: SearchHistoryProps) => {
  const { t } = useTranslation();
  const filteredRecentSearches = Array.from(new Set(recentSearches));
  const [selectedSearch, setSelectedSearch] = useState("");

  return topSearches.length > 0 || recentSearches.length > 0 ? (
    <div className="flex flex-col px-6 pb-4">
      {filteredRecentSearches.length > 0 && (
        <>
          <span className="search-title">{t("searches_history")}</span>
          <div className="flex flex-row gap-2 flex-wrap">
            {filteredRecentSearches.map((item) => (
              <a
                href={`${StaticLinks.Search}?subject=${item}`}
                key={item}
                onMouseEnter={() => {
                  setSelectedSearch(item);
                }}
                onMouseLeave={() => {
                  setSelectedSearch("");
                }}
                className="search-history-tag relative"
              >
                <span
                  className={
                    selectedSearch == item
                      ? "text-transparent whitespace-nowrap"
                      : "whitespace-nowrap"
                  }
                >
                  {item}
                </span>
                {selectedSearch == item && (
                  <span className="absolute top-0 left-0 search-history-tag flex flex-row flex-nowrap whitespace-nowrap">
                    {item}

                    <CloseOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        // onClick处理重新使弹出框显示
                        onClick();
                        handleDeleteSearchRecord(item);
                      }}
                    />
                  </span>
                )}
              </a>
            ))}
          </div>
        </>
      )}
      <span className="search-title py-4">{t("top_searches")}</span>
      <div className="flex flex-row gap-2 flex-wrap">
        {topSearches
          .filter((w) => w)
          .map((item, index) => (
            <a
              href={`${StaticLinks.Search}?subject=${item}`}
              key={item}
              className="search-history-tag"
            >
              <span> {item}</span>
            </a>
          ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SearchHistory;
