"use client";

import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./i18n";
import "normalize.css";
import Header, { HeaderShoppingCartCount } from "./components/header";
import Footer from "./components/footer";

import { Provider, useDispatch } from "react-redux";
import {
  persistor,
  store,
  syncDataToAllBrowserTabs,
  useTypedSelector,
} from "./lib/state/store";
import { App, ConfigProvider } from "antd";
import { AndtdCssCustomization } from "./lib/css/antd-css-customization";

import { configurationApi } from "./lib/api/configuration";
import {
  updateCategories,
  updateFlatCategoriesForNavbar,
  updateCategoriesTrans,
} from "./lib/state/category.slice";
import { convertCategoriesToMenuItems } from "./lib/util/api.data.helper";
import { loadLanguages } from "./lib/state/lang.slice";
import { apiClient } from "./lib/util/http";
import {
  WebSocketContext,
  WebSocketProvider,
} from "./lib/ws/websocket.context";
import { PersistGate } from "redux-persist/integration/react";
import { useRouter } from "next/navigation";
import { StaticLinks } from "./lib/route/link";
import { userApi } from "./lib/api/user";
import { setToken, setUserProfile } from "./lib/state/user.slice";
import ErrorBoundary from "./components/error.boundary";
import { message } from "antd";
import HeaderLinks from "./components/header.links";
import { Constants } from "./lib/constant";
import ScrollToTopButton from "./components/scroll.to.top.button";
import SocialShareButtons from "./components/social.share.buttons";
import {
  useMediaQueryList,
  useMediaQueryListSize,
} from "./lib/hooks/media.query";
import { FooterNav } from "./components/footer.nav";
import {
  defaultOfferPricesContextValue,
  OfferPricesContext,
  OfferPricesType,
} from "./lib/context/offer.prices";

export function MainLayout({ children }: RootLayoutProps) {
  const { isNonDesktop } = useMediaQueryList();
  const paddings = useMediaQueryListSize([
    "p-[16px] pt-[62px] pb-[72px]",
    "p-[54px] pt-[115px] pb-[115px]",
    "p-[62px] pt-[115px] pb-[115px]",
    "p-[0px] pt-[172px] pb-[0px] max-w-[1108px]",
    "p-[0px] pt-[168px] pb-[0px] max-w-[1420px]",
  ]);
  // 全局设置默认显示时间为 10 秒
  message.config({
    duration: 10,
  });

  const [value, setValue] = useState<OfferPricesType>(
    defaultOfferPricesContextValue
  );

  return (
    <React.StrictMode>
      <ConfigProvider
        theme={{
          token: AndtdCssCustomization,
        }}
      >
        <App>
          <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
              <WebSocketProvider>
                <ErrorBoundary>
                  <OfferPricesContext.Provider value={{ value, setValue }}>
                    <div className="flex justify-center items-start">
                      <div
                        style={{
                          minHeight: "80vh",
                        }}
                        className="max-w-[1920px] w-full"
                      >
                        <div
                          className="w-full px-6 lg:px-10 flex justify-center absolute"
                          style={{ zIndex: Constants.ZIndex.MOSTTOP }}
                        >
                          <HeaderLinks />
                        </div>
                        <HeaderShoppingCartCount />
                        {isNonDesktop && <FooterNav />}
                        <Header />

                        <div
                          className={`w-full p-0 ${paddings}`}
                          style={{ margin: "auto" }}
                        >
                          <ReactRoot>{children}</ReactRoot>
                          <div className="w-full bg-white flex-grow">
                            <Footer />
                          </div>
                        </div>
                      </div>
                      <SocialShareButtons />
                      <ScrollToTopButton />
                    </div>
                  </OfferPricesContext.Provider>
                </ErrorBoundary>
              </WebSocketProvider>
            </PersistGate>
          </Provider>
        </App>
      </ConfigProvider>
    </React.StrictMode>
  );
}

export default function ReactRoot({ children }: RootLayoutProps) {
  const { closeWs } = useContext(WebSocketContext);
  const router = useRouter();
  const dispatch = useDispatch();
  const [data, setData] = useState<
    Pick<
      APIConfigurationResponseType,
      "categories" | "categoriesTrans" | "languages"
    >
  >({
    categories: [],
    categoriesTrans: [],
    languages: [],
  });

  useEffect(() => {
    configurationApi.getConfigurationCategoriesLanguages().then((data) => {
      setData((w) => ({ ...w, ...data.data }));
    });
  }, []);

  const hiarachechyCategories = useMemo(() => {
    return convertCategoriesToMenuItems(data.categories);
  }, [data]);

  const flatCategoriesForNavbar = useMemo(() => {
    return data.categories
      .filter((w) => w.showOnNavbar)
      .map(
        (w) =>
          ({
            key: `${w.categoryId}`,
            label: w.name || "",
            collectionId: `${w.collectionId || ""}`,
            externalId: `${w.externalId || ""}`,
          } as MenuItem)
      );
  }, [data]);

  useEffect(() => {
    hiarachechyCategories && dispatch(updateCategories(hiarachechyCategories));
    flatCategoriesForNavbar &&
      dispatch(updateFlatCategoriesForNavbar(flatCategoriesForNavbar));
    data.categoriesTrans &&
      dispatch(updateCategoriesTrans(data.categoriesTrans));
    data.languages && dispatch(loadLanguages(data.languages));
  }, [hiarachechyCategories, flatCategoriesForNavbar, data, dispatch]);

  // 从store读取token并设置到httpClient，此token源自登录之后的token设置
  // TODO token失效如何处理？增加一个API接口来检查用户登录状态是否依旧有效
  const token = useTypedSelector((state) => state.user.token);
  useEffect(() => {
    if (token) {
      apiClient.setToken(token);
    }
  }, [token]);

  // 为apiClient添加跳转登录回调函数
  useEffect(() => {
    apiClient.setFnRedirect(async () => {
      await userApi.logout();
      dispatch(setUserProfile(null));
      dispatch(setToken({ token: "" }));
      apiClient.setToken("");
      syncDataToAllBrowserTabs();
      // 关闭websocket连接
      closeWs();
      router.push(`${StaticLinks.Login}?redirectTo=${window.origin}`);
    });
    apiClient.setFn401Redirect(async (redirectUrl: string) => {
      router.push(StaticLinks.LoginWithRedirect + redirectUrl);
    });
  }, [closeWs, dispatch, router]);

  return <>{children}</>;
}
