import "./header.css";
import TopNav, { TopNavShoppingCartCount } from "./header.topnav";
import { Constants } from "../lib/constant";
import { useContext, useEffect, useRef, useState } from "react";
import React from "react";
import {
  useMediaQueryList,
  useMediaQueryListSize,
} from "../lib/hooks/media.query";
import { OfferPricesContext } from "../lib/context/offer.prices";
import { usePathname } from "next/navigation";

function Header() {
  const { isNonDesktop } = useMediaQueryList();
  const lastScrollTop = useRef<number>(0);
  const stickyRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (stickyRef.current) {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop.current && currentScrollTop > 40) {
        stickyRef.current.style.display = "none";
        stickyRef.current.style.top = "0px";
        stickyRef.current.style.position = "fixed";
      } else {
        stickyRef.current.style.display = "flex";
        stickyRef.current.style.top = "0px";
        stickyRef.current.style.position = "auto";
      }

      lastScrollTop.current = currentScrollTop;
    }
  };

  useEffect(() => {
    // 避免频繁触发滚动事件导致反复刷新
    const handleAddEventScroll = () => {
      window.addEventListener("scroll", handleScroll);
    };
    handleAddEventScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerHeightCss = "h-[48px] md:h-[60px] xl:h-[100px] 2xl:h-[100px]";
  const pr = useMediaQueryListSize(["pr-0", "pr-2", "pr-2", "pr-2", "pr-2"]);
  return (
    // 这个div是占位符，高度h-24和header一致，避免header固定到顶部之后出现滚动条往上滚
    <div className={headerHeightCss}>
      <header
        ref={stickyRef}
        // main-top-header 用来定位设置sticky元素的top值
        id="main-top-header"
        className={`w-full bg-transparent flex flex-col bg-color max-w-[1920px] ${headerHeightCss}`}
        style={{
          zIndex: Constants.ZIndex.STICKY,
        }}
      >
        <div className="flex justify-center">
          <div
            className={`flex flex-grow justify-center py-2 ${pr} zr:ml-[16px] md:ml-[54px] lg:ml-[62px] xl:ml-[10vw] 2xl:ml-[250px] ${headerHeightCss}`}
          >
            {isNonDesktop && (
              <div className="pr-4 flex flex-1 whitespace-nowrap items-center justify-center space-x-[12px]">
                <TopNav />
              </div>
            )}
            {!isNonDesktop && (
              <div className="flex flex-1 whitespace-nowrap items-end space-x-[12px]">
                <TopNav />
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
}

export function HeaderShoppingCartCount() {
  const pathname = usePathname();
  const { value: offerPrices } = useContext(OfferPricesContext);
  const { isNonDesktop } = useMediaQueryList();
  const headerVisible = useVisibleHeader();
  const pr = useMediaQueryListSize(["pr-4", "pr-6", "pr-6", "pr-2", "pr-2"]);
  const headerHeightCss = "h-[48px] md:h-[60px] xl:h-[100px] 2xl:h-[100px]";
  const prcartcontainer = useMediaQueryListSize([
    "",
    "pr-4",
    "pr-4",
    "pr-4",
    "pr-4",
  ]);
  return headerVisible ||
    !offerPrices.offerId ||
    !offerPrices.offerData ||
    !pathname.includes(`/${offerPrices.offerId}`) ? (
    <></>
  ) : (
    <div
      className={headerHeightCss + " fixed top-0 left-0 w-full"}
      style={{ zIndex: Constants.ZIndex.STICKY }}
    >
      <header
        className={`w-full bg-transparent flex flex-col bg-color max-w-[1920px] ${headerHeightCss}`}
        style={{
          zIndex: Constants.ZIndex.STICKY,
        }}
      >
        <div className="flex justify-center">
          <div
            className={`flex flex-grow justify-center py-2 ${pr} zr:ml-[16px] md:ml-[54px] lg:ml-[62px] xl:ml-[10vw] 2xl:ml-[250px] ${headerHeightCss}`}
          >
            {isNonDesktop && (
              <div
                className={`${prcartcontainer} flex flex-1 whitespace-nowrap items-center justify-center space-x-[12px]`}
              >
                <TopNavShoppingCartCount />
              </div>
            )}
            {!isNonDesktop && (
              <div className="flex flex-1 whitespace-nowrap items-end space-x-[12px]">
                <TopNavShoppingCartCount />
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
}

export function useVisibleHeader() {
  const [headerVisible, setHeaderVisible] = useState(false);
  const { isNonDesktop } = useMediaQueryList();

  useEffect(() => {
    if (isNonDesktop) return;

    const handleScroll = () => {
      const element = document.querySelector("#main-top-header");
      if (element) {
        const style = window.getComputedStyle(element);
        setHeaderVisible(style.display !== "none");
      }
    };
    // 避免频繁触发滚动事件导致反复刷新
    const handleAddEventScroll = () => {
      window.addEventListener("scroll", handleScroll);
    };
    handleAddEventScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isNonDesktop]);

  return headerVisible;
}

export default React.memo(Header);
