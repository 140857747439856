export function OfferAttributesFilter(key: string) {
  const excludes = [
    "是",
    "否",
    "有",
    "无",
    "大",
    "小",
    "其它",
    "其他",
    "含",
    "不含",
    "不包含",
    "软",
    "硬",
    "男",
    "女",
    "查看详情",
    "货号",
    "规格",
    "支持",
    "不支持",
    "主要下游平台",
    "毛重",
    "价格段",
    "价格",
    "使用寿命",
    "寿命",
    "货号",
    "耐腐蚀度",
    "尺寸",
    "产地",
  ];
  const partOf = [
    "跨境",
    "电商",
    "清仓",
    "=",
    "商家",
    "快手",
    "货源",
    "货号",
    "非洲",
    "欧洲",
    "南美",
    "东南亚",
    "北美",
    "东北亚",
    "中东",
    "中国",
    "其他",
    "俄罗斯",
    "ebay",
    "亚马逊",
    "速卖通",
    "独立站",
    "LAZADA",
    "其他",
    "其它",
    "不可",
    "等品",
    "占比",
    "%",
    "kg",
    "KG",
    "级",
    "毛重",
    "价格段",
    "价格",
    "使用寿命",
    "寿命",
    "货号",
    "承重",
    "率",
    "度",
    "配送",
    "元",
    "上门",
    "服务",
    "产地",
    "货",
  ];
  const isNumberOrOperator = /^\d+([+\-\*\/]\d+)*$/;
  const isSpaceOrDashOrNumberOrTian = /^[\s--~\d天]*$/;
  const isDimension = /^[\d\.\*cmCM]*$/;
  const isEndWith = /(\d)+[VvCc]+$/;
  if (isNumberOrOperator.test(key)) return false;
  if (isSpaceOrDashOrNumberOrTian.test(key)) return false;
  if (isDimension.test(key)) return false;
  if (isEndWith.test(key)) return false;
  if (excludes.includes(key)) return false;
  if (partOf.some((part) => key.includes(part))) return false;
  return true;
}

export function OfferAttributesHidden(key: string) {
  const partOf = [
    "跨境",
    "电商",
    "清仓",
    "商家",
    "快手",
    "货源",
    "ebay",
    "亚马逊",
    "速卖通",
    "独立站",
    "LAZADA",
  ];
  if (partOf.some((part) => key.includes(part))) return false;
  return true;
}

export function filterInvalidUnitString(unit: string) {
  const invalidUnit = /[\?]+/;
  return invalidUnit.test(unit) ? "единиц(и)" : unit;
}
