"use client";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../lib/state/store";
import {
  convertMenuItems2FlatCategories,
  translateCategories,
} from "../lib/util/api.data.helper";
import Link from "next/link";
import { RightOutlined } from "@ant-design/icons";
import { StaticLinks } from "../lib/route/link";
import {
  useMediaQueryList,
  useMediaQueryListSize,
} from "../lib/hooks/media.query";
import Image from "next/image";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { setShowCategoryMenu } from "../lib/state/ui.slice";

function HeaderLinks3Cols() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const categories = useTypedSelector((state) => state.categories.categories);
  const flatCategoriesForNavbar = useTypedSelector(
    (state) => state.categories.flatCategoriesForNavbar || []
  );
  const categoriesTrans = useTypedSelector(
    (state) => state.categories.categoriesTrans
  );
  const { translatedCategoriesAll, categoriesToShow } = useMemo(() => {
    const retArray = convertMenuItems2FlatCategories(flatCategoriesForNavbar);
    return {
      categoriesToShow: translateCategories(
        retArray,
        categoriesTrans,
        language
      ),
      translatedCategoriesAll: translateCategories(
        categories,
        categoriesTrans,
        language
      ),
    };
  }, [categories, language, categoriesTrans, flatCategoriesForNavbar]);

  const [activeKey, setActiveKey] = useState("");
  const [selectedLevel2Child, setSelectedLevel2Child] =
    useState<MenuItem | null>(null);
  const hasChildren = (item: MenuItem) =>
    !!item.children?.length && item.children?.length > 0;

  // 渲染子菜单
  const renderChildren = (level: number, children?: MenuItem[]) => {
    return (
      <>
        <div className="shadow column-list-2">
          {children?.map((child) => {
            const selected =
              child.key === selectedLevel2Child?.key && level === 2;
            return (
              <div
                key={child.key}
                className={`category-item-hover-bg ${
                  selected ? "active-category-item" : ""
                }`}
              >
                <Link
                  href={
                    !!child.collectionId
                      ? `${StaticLinks.SearchCollection}${child.collectionId}`
                      : !!child.externalId
                      ? `${StaticLinks.Category}${child.externalId}`
                      : "#"
                  }
                  onMouseEnter={() =>
                    level == 2 && setSelectedLevel2Child(child)
                  }
                >
                  <span className="image-label flex-grow child-col-padding flex flex-row items-center justify-between flex-nowrap">
                    <span className="flex-child-clamp-1">{child.label}</span>
                    {hasChildren(child) && selected && (
                      <RightOutlined className="arrow" />
                    )}
                  </span>
                </Link>
              </div>
            );
          })}
        </div>
        {level === 2 && selectedLevel2Child?.children && (
          <div className="flex flex-wrap gap-4">
            {renderChildren(3, selectedLevel2Child.children)}
          </div>
        )}
      </>
    );
  };

  const menuData = translatedCategoriesAll;
  const renderMenu = () => {
    const activeItem = menuData.find((item) => item.key === activeKey);

    return (
      <div
        className="category-list flex flex-row flex-grow"
        onMouseLeave={() => {
          setActiveKey("");
          setSelectedLevel2Child(null);
        }}
      >
        <div className="shadow column-list-1">
          {menuData.map((item) => (
            <div
              key={item.key}
              className={`category-item-hover-bg ${
                item.key === activeKey ? "active-category-item" : ""
              }`}
              onMouseEnter={() => {
                setActiveKey(item.key);
                setSelectedLevel2Child(null);
              }}
            >
              <Link
                href={
                  !!item.collectionId
                    ? `${StaticLinks.SearchCollection}${item.collectionId}`
                    : !!item.externalId
                    ? `${StaticLinks.Category}${item.externalId}`
                    : `#`
                }
                className="flex-grow flex flex-row items-center gap-2"
              >
                <Image
                  src={item.categoryImage || "/no-image-to-show.jpg"}
                  alt="img"
                  width="50"
                  height="50"
                  className="category-image"
                />
                <span className="image-label flex-grow fix-to-width flex flex-row items-center justify-between flex-nowrap">
                  <span className="flex-child-clamp-1">{item.label}</span>
                  {hasChildren(item) && item.key === activeKey && (
                    <RightOutlined className="arrow" />
                  )}
                </span>
              </Link>
            </div>
          ))}
        </div>
        {activeItem && renderChildren(2, activeItem.children)}
      </div>
    );
  };

  const height = useMediaQueryListSize([
    "max-h-[100vh]",
    "max-h-[100vh]",
    "max-h-[100vh]",
    "h-[100vh]",
    "h-[100vh]",
  ]);

  return (
    <>
      <div
        className={
          "fixed top-0 left-0 h-[100vh] backdrop-bg " +
          (activeKey ? "w-full" : "")
        }
      ></div>
      <div
        className={`flex flex-col fixed left-0 top-0 bg-white ${height} scroll overflow-y-auto thin-scrollbar`}
      >
        {renderMenu()}
      </div>
    </>
  );
}

function HeaderLinks2Cols() {
  const dispatch = useDispatch();
  const showCategoryMenu = useTypedSelector((state) => state.ui.showCategory);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const categories = useTypedSelector((state) => state.categories.categories);
  const flatCategoriesForNavbar = useTypedSelector(
    (state) => state.categories.flatCategoriesForNavbar || []
  );
  const categoriesTrans = useTypedSelector(
    (state) => state.categories.categoriesTrans
  );
  const { translatedCategoriesAll, categoriesToShow } = useMemo(() => {
    const retArray = convertMenuItems2FlatCategories(flatCategoriesForNavbar);
    return {
      categoriesToShow: translateCategories(
        retArray,
        categoriesTrans,
        language
      ),
      translatedCategoriesAll: translateCategories(
        categories,
        categoriesTrans,
        language
      ),
    };
  }, [categories, language, categoriesTrans, flatCategoriesForNavbar]);

  const menuData = translatedCategoriesAll;

  const [activeKey, setActiveKey] = useState("");
  const [activeKeyLabel, setActiveKeyLabel] = useState("");
  const [activeKeyLabelTopLevel, setActiveKeyLabelTopLevel] = useState("");
  const hasChildren = (item: MenuItem) =>
    !!item.children?.length && item.children?.length > 0;

  const onClose = () => {
    dispatch(setShowCategoryMenu(false));
  };

  const goBack = useCallback(() => {
    const keys = [] as string[];
    const labels = [] as string[];
    function findItem(items: MenuItem[], key: string): MenuItem[] | undefined {
      for (const item of items) {
        if (item.key === key) return items;
        if (item.children) {
          keys.push(item.key);
          labels.push(item.label);
          const found = findItem(item.children, key);
          if (found) return found;
          keys.pop();
          labels.pop();
        }
      }
      return undefined;
    }
    const items = findItem(menuData, activeKey);
    items && setShowMenuItems(items);

    setActiveKey(keys.pop() || "");
    setActiveKeyLabel(labels.pop() || "");
    setActiveKeyLabelTopLevel(labels.pop() || "");
  }, [activeKey, menuData]);

  const findActiveKeyParentKey = () => {
    return menuData.find(
      (w) => w.key === activeKey || w.children?.find((x) => x.key === activeKey)
    )?.key;
  };

  const [showMenuItems, setShowMenuItems] = useState<MenuItem[]>(menuData);
  const renderMenu = () => {
    return (
      <div className="flex flex-row flex-grow">
        <div className="shadow h-[100vh] bg-white">
          <div className="flex flex-row justify-end items-center p-4">
            <Button
              icon={
                <Image src="/close.svg" alt="close" width="12" height="12" />
              }
              className="transparent-style"
              onClick={onClose}
              type="text"
              style={{ height: "50px" }}
            ></Button>
          </div>
          <div className="flex flex-row justify-between items-start px-2 bg-white">
            <div className="flex-grow">
              {menuData.map((item) => (
                <div
                  key={item.key}
                  className={`pr-2 flex-grow flex flex-row items-center gap-2 w-[384px] ${
                    item.key === activeKey ||
                    item.key === findActiveKeyParentKey()
                      ? "active-category-item"
                      : ""
                  }`}
                >
                  <Link
                    href={
                      !!item.collectionId
                        ? `${StaticLinks.SearchCollection}${item.collectionId}`
                        : !!item.externalId
                        ? `${StaticLinks.Category}${item.externalId}`
                        : `#`
                    }
                    onClick={onClose}
                  >
                    <Image
                      src={item.categoryImage || "/no-image-to-show.jpg"}
                      alt="img"
                      width="50"
                      height="50"
                      className="category-image"
                      style={{
                        visibility: item.categoryImage ? "visible" : "hidden",
                        width: item.categoryImage ? 50 : 0,
                      }}
                    />
                  </Link>
                  <span className="flex-grow fix-to-width flex flex-row items-center justify-between flex-nowrap">
                    <Link
                      href={
                        !!item.collectionId
                          ? `${StaticLinks.SearchCollection}${item.collectionId}`
                          : !!item.externalId
                          ? `${StaticLinks.Category}${item.externalId}`
                          : `#`
                      }
                      onClick={onClose}
                      className="max-w-[80%]"
                    >
                      <span className="flex-child-clamp-1">{item.label}</span>
                    </Link>
                    {hasChildren(item) && (
                      <Button
                        icon={
                          <RightOutlined
                            className={`arrow ${
                              findActiveKeyParentKey() === item.key
                                ? "rotate180"
                                : ""
                            }`}
                          />
                        }
                        onClick={() => {
                          setActiveKey(item.key);
                          setActiveKeyLabel(item.label);
                          setShowMenuItems(item.children || []);
                        }}
                        className="transparent-style"
                        type="text"
                      />
                    )}
                  </span>
                </div>
              ))}
            </div>
            {activeKey && (
              <div className="flex-grow">
                {activeKey && (
                  <div className="pr-2 flex-grow flex flex-row items-center gap-2 w-[384px]">
                    <div
                      className="category-image"
                      style={{
                        visibility: "hidden",
                        width: 0,
                      }}
                    />
                    <Button
                      icon={
                        <Image
                          src="/arrow-left-long.png"
                          alt="back"
                          width="50"
                          height="50"
                        />
                      }
                      className="transparent-style"
                      onClick={goBack}
                      style={{ width: "50px", height: "50px" }}
                    ></Button>

                    <span className="flex-grow text-center">
                      {activeKeyLabel}
                    </span>
                  </div>
                )}
                {showMenuItems.map((item) => (
                  <div
                    key={item.key}
                    className="pr-2 flex-grow flex flex-row items-center gap-2 w-[384px]"
                  >
                    <Link
                      href={
                        !!item.collectionId
                          ? `${StaticLinks.SearchCollection}${item.collectionId}`
                          : !!item.externalId
                          ? `${StaticLinks.Category}${item.externalId}`
                          : `#`
                      }
                      onClick={onClose}
                    >
                      <Image
                        src={item.categoryImage || "/no-image-to-show.jpg"}
                        alt="img"
                        width="50"
                        height="50"
                        className="category-image"
                        style={{
                          visibility: item.categoryImage ? "visible" : "hidden",
                          width: item.categoryImage ? 50 : 0,
                        }}
                      />
                    </Link>
                    <span className="flex-grow fix-to-width flex flex-row items-center justify-between flex-nowrap">
                      <Link
                        href={
                          !!item.collectionId
                            ? `${StaticLinks.SearchCollection}${item.collectionId}`
                            : !!item.externalId
                            ? `${StaticLinks.Category}${item.externalId}`
                            : `#`
                        }
                        onClick={onClose}
                        className="max-w-[80%]"
                      >
                        <span className="flex-child-clamp-1">{item.label}</span>
                      </Link>
                      {hasChildren(item) && (
                        <Button
                          icon={<RightOutlined className="arrow" />}
                          onClick={() => {
                            setActiveKey(item.key);
                            setActiveKeyLabel(item.label);
                            setShowMenuItems(item.children || []);
                          }}
                          className="transparent-style"
                        />
                      )}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return showCategoryMenu ? (
    <div className="flex flex-col fixed left-0 top-0 backdrop-bg w-[100vw] max-h-[100vh] scroll overflow-y-auto thin-scrollbar">
      {renderMenu()}
    </div>
  ) : (
    <></>
  );
}

function HeaderLinks1Col() {
  const dispatch = useDispatch();
  const showCategoryMenu = useTypedSelector((state) => state.ui.showCategory);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const categories = useTypedSelector((state) => state.categories.categories);
  const flatCategoriesForNavbar = useTypedSelector(
    (state) => state.categories.flatCategoriesForNavbar || []
  );
  const categoriesTrans = useTypedSelector(
    (state) => state.categories.categoriesTrans
  );
  const { translatedCategoriesAll, categoriesToShow } = useMemo(() => {
    const retArray = convertMenuItems2FlatCategories(flatCategoriesForNavbar);
    return {
      categoriesToShow: translateCategories(
        retArray,
        categoriesTrans,
        language
      ),
      translatedCategoriesAll: translateCategories(
        categories,
        categoriesTrans,
        language
      ),
    };
  }, [categories, language, categoriesTrans, flatCategoriesForNavbar]);

  const menuData = translatedCategoriesAll;

  const [activeKey, setActiveKey] = useState("");
  const [activeKeyLabel, setActiveKeyLabel] = useState("");
  const hasChildren = (item: MenuItem) =>
    !!item.children?.length && item.children?.length > 0;

  const onClose = () => {
    dispatch(setShowCategoryMenu(false));
  };

  const goBack = useCallback(() => {
    const keys = [] as string[];
    const labels = [] as string[];
    function findItem(items: MenuItem[], key: string): MenuItem[] | undefined {
      for (const item of items) {
        if (item.key === key) return items;
        if (item.children) {
          keys.push(item.key);
          labels.push(item.label);
          const found = findItem(item.children, key);
          if (found) return found;
          keys.pop();
          labels.pop();
        }
      }
      return undefined;
    }
    const items = findItem(menuData, activeKey);
    items && setShowMenuItems(items);

    setActiveKey(keys.pop() || "");
    setActiveKeyLabel(labels.pop() || "");
  }, [activeKey, menuData]);

  const [showMenuItems, setShowMenuItems] = useState<MenuItem[]>(menuData);
  const renderMenu = () => {
    return (
      <div className="flex flex-row flex-grow">
        <div className="shadow w-full h-[100vh]">
          <div className="flex flex-row justify-end items-center p-2">
            {activeKey && (
              <Button
                icon={
                  <Image
                    src="/arrow-left-long.png"
                    alt="back"
                    width="50"
                    height="50"
                  />
                }
                className="transparent-style ml-2 w-[50px]"
                onClick={goBack}
                style={{ width: "50px", height: "50px" }}
              ></Button>
            )}
            <span className="flex-grow text-center">{activeKeyLabel}</span>
            <Button
              icon={
                <Image src="/close.svg" alt="close" width="12" height="12" />
              }
              className="transparent-style"
              onClick={onClose}
              style={{ height: "50px" }}
            ></Button>
          </div>
          {showMenuItems.map((item) => (
            <div
              key={item.key}
              className="pr-2 flex-grow flex flex-row items-center gap-2 bg-white"
            >
              <Image
                src={item.categoryImage || "/no-image-to-show.jpg"}
                alt="img"
                width="50"
                height="50"
                className="category-image"
                style={{
                  visibility: item.categoryImage ? "visible" : "hidden",
                  width: item.categoryImage ? 50 : 0,
                }}
              />
              <span className="flex-grow fix-to-width flex flex-row items-center justify-between flex-nowrap">
                <Link
                  href={
                    !!item.collectionId
                      ? `${StaticLinks.SearchCollection}${item.collectionId}`
                      : !!item.externalId
                      ? `${StaticLinks.Category}${item.externalId}`
                      : `#`
                  }
                  onClick={onClose}
                  className="max-w-[80%]"
                >
                  <span className="flex-child-clamp-1">{item.label}</span>
                </Link>
                {hasChildren(item) && (
                  <Button
                    icon={<RightOutlined className="arrow" />}
                    onClick={() => {
                      setActiveKey(item.key);
                      setActiveKeyLabel(item.label);
                      setShowMenuItems(item.children || []);
                    }}
                    className="transparent-style"
                  />
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return showCategoryMenu ? (
    <div className="flex flex-col fixed left-0 top-0 bg-white max-h-[100vh] w-[100vw] scroll overflow-y-auto thin-scrollbar">
      {renderMenu()}
    </div>
  ) : (
    <></>
  );
}

function HeaderLinks() {
  const { isMobile, isTablet, isTabletWide } = useMediaQueryList();
  return isMobile ? (
    <HeaderLinks1Col />
  ) : isTablet || isTabletWide ? (
    <HeaderLinks2Cols />
  ) : (
    <HeaderLinks3Cols />
  );
}

export default HeaderLinks;
