import { useTranslation } from "react-i18next";
import "./footer.css";
import Image from "next/image";
import { Typography } from "antd";
import {
  useMediaQueryList,
  useMediaQueryListSize,
} from "../lib/hooks/media.query";

const Footer = () => {
  const { isMobile, isTablet, isTabletWide } = useMediaQueryList();
  const { t } = useTranslation();
  const width = useMediaQueryListSize([150, 200, 335, 335, 335]);
  const height = useMediaQueryListSize([35, 44, 77, 77, 77]);
  const cls = useMediaQueryListSize([
    {
      footer: "pl-[12px] pt-[20px] pb-[40px] pr-[12px] mt-[72px]",
      imgmr: "",
      topmb: "mb-[72px]",
      topgrid: "",
      linegap: "ml-[12px] pr-[12px] h-[32px] self-center",
    },
    {
      footer: "pl-[28px] pt-[40px] pb-[40px] mt-[120px]",
      imgmr: " pr-[24px]",
      topmb: "mb-[160px]",
      topgrid: "grid grid-cols-12",
      linegap: "ml-[12px] pr-[12px] h-[32px] self-center",
    },
    {
      footer: "pl-[28px] pt-[40px] pb-[40px] mt-[160px]",
      imgmr: " pr-[54px]",
      topmb: "mb-[160px]",
      topgrid: "grid grid-cols-12",
      linegap: "ml-[12px] pr-[12px] h-[32px] self-center",
    },
    {
      footer: "pl-[54px] pt-[40px] pb-[40px] mt-[160px]",
      imgmr: " pr-[54px]",
      topmb: "mb-[160px]",
      topgrid: "grid grid-cols-12",
      linegap: "ml-[12px] pr-[12px] h-[32px] self-center",
    },
    {
      footer: "pl-[52px] pt-[40px] pb-[40px] mt-[200px]",
      imgmr: " pr-[73px]",
      topmb: "mb-[200px]",
      topgrid: "grid grid-cols-12",
      linegap: "ml-[20px] pr-[20px] h-[38px] self-center",
    },
  ]);
  return (
    <footer className={"footer " + cls.footer}>
      <div className={`${cls.topmb}  gap-2 ${cls.topgrid}`}>
        <div
          className={
            isTablet || isMobile
              ? "col-span-7 flex flex-col"
              : "col-span-5 flex flex-col"
          }
        >
          <Typography.Text className="company-name mb-[12px]">
            {t("company_hangzhouyoumai")}
          </Typography.Text>
          <div>
            {t("company_address")}
            <br />
            {t("company_tel")}
            <br />
            <a href={`mailto:${t("company_email")}`} className="underline">
              {t("company_email")}
            </a>
          </div>
        </div>
        <div
          className={
            isTablet || isMobile
              ? "col-span-5 grid grid-cols-1"
              : "col-span-6 grid grid-cols-3"
          }
        >
          <div className={`col-span-1 ${isMobile ? "mt-[52px]" : ""}`}>
            <ul>
              {/* <li>
              <a href="/company/company-introduction.html">
                {t("company_about")}
              </a>
            </li> */}
              <li>
                <a href="/company/scope-of-business.html">
                  {t("company_service")}
                </a>
              </li>
              <li>
                <a href="/company/contact.html">{t("company_contact")}</a>
              </li>
            </ul>
          </div>
          <div className="col-span-1">
            <ul>
              <li>
                <a
                  href="/company/platform-fee.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("serviceFares")}
                </a>
              </li>
              <li>
                <a
                  href="/company/custom-clearance.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("customClearance")}
                </a>
              </li>
              <li>
                <a
                  href="/company/individual.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("customer_individuals")}
                </a>
              </li>
              <li>
                <a
                  href="/company/retail.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("customer_retail")}
                </a>
              </li>
              <li>
                <a
                  href="/company/oem-requirements.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("customer_oem")}
                </a>
              </li>
              <li>
                <a
                  href="/company/vip.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("customer_vip")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-span-1">
            <ul>
              <li>
                <a
                  href="/company/how-to-order.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("support_how_to_order")}
                </a>
              </li>
              <li>
                <a
                  href="/company/payment.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("support_payment")}
                </a>
              </li>
              <li>
                <a
                  href="/company/delivery-information.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("support_logistics")}
                </a>
              </li>
              <li>
                <a
                  href="/company/product-return.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("support_return")}
                </a>
              </li>
              <li>
                <a
                  href="/company/product-refund.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("support_refund")}
                </a>
              </li>
              <li>
                <a
                  href="/company/shopping-points.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("support_bonus_points")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className={`grid ${
          isMobile ? "grid-cols-3" : "grid-cols-6"
        } footer-bottom`}
      >
        <div className="col-span-3 flex justify-start">
          <div className="flex flex-col">
            <span className="copyright mb-[16px]">
              &copy; {t("copyright1")}
              <br />
              {t("copyright2")}
            </span>
            <ul className={`flex mt-[16px] ${isMobile ? "flex-col" : ""}`}>
              <li>
                <a
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="privacy"
                >
                  {t("privacy_policy1")}
                  <br />
                  {t("privacy_policy2")}
                </a>
              </li>
              {!isMobile && (
                <li className="flex">
                  <span
                    className={`${cls.linegap} border-l-2 border-gray-300`}
                  ></span>
                </li>
              )}
              <li>
                <a
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="privacy flex items-end"
                >
                  {t("terms_conditions")}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`col-span-3 flex ${
            isMobile ? "justify-start mt-[16px]" : "justify-end items-end"
          }`}
        >
          <Image
            src="/logo-white-compact.png"
            alt="logo"
            width={width}
            height={height}
            style={{ width, height }}
            className={cls.imgmr}
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
