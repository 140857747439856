import { CurrencyMappingToLocaleInfo } from "../language/lang.config";

export namespace Price {
  // 价格四舍五入取两位小数
  export function price2d(price: number) {
    return Math.round(price * 100) / 100;
  }
  export function formattedAmount(amount: number, currencySymbol: string) {
    const { locale, currency } = CurrencyMappingToLocaleInfo[currencySymbol];
    return amount
      ? locale && currency
        ? amount.toLocaleString(locale, {
            style: "currency",
            currency,
          })
        : amount
      : "-";
  }
}
