"use client";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import {
  useMediaQueryList,
  useMediaQueryListSize,
} from "../lib/hooks/media.query";

const ScrollToTopButton: React.FC = () => {
  const { isMobile } = useMediaQueryList();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const buttonSize = useMediaQueryListSize<string>([
    "",
    "",
    "",
    "48px",
    "48px",
  ]);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {isVisible && buttonSize && (
        <Button
          type="text"
          icon={
            <svg
              width="48px"
              height="48px"
              viewBox="0 0 52 52"
              className="social-media-icon"
            >
              <rect width="52" height="52" fill="white" />
              <g id="SVGRepo_iconCarrier" fill="#000000 ">
                <path d="M9.66,10.17a2,2,0,0,1-2-2V4a2,2,0,0,1,2-2H42.34a2,2,0,0,1,2,2V8.13a2,2,0,0,1-2,2ZM24,50a2.12,2.12,0,0,1-2.05-2h0V30.6a1.3,1.3,0,0,0-2.24-.92h0l-4.8,4.69a2,2,0,0,1-2.86,0h0L9.15,31.62a1.86,1.86,0,0,1,0-2.76h0l15.42-15a2,2,0,0,1,2.86,0h0l15.32,15a1.86,1.86,0,0,1,0,2.76h0L40,34.37a2,2,0,0,1-2.86,0h0l-4.8-4.69a1.3,1.3,0,0,0-2.24.92h0V48A2.19,2.19,0,0,1,28,50H24Z"></path>
              </g>
            </svg>
          }
          style={{
            position: "fixed",
            bottom: isMobile ? "229px" : "200px",
            right: "4px",
            zIndex: 1000,
            width: isMobile ? "45px" : "48px",
            height: isMobile ? "45px" : "48px",
          }}
          size="large"
          onClick={scrollToTop}
          className="social-media-button"
        />
      )}
    </>
  );
};

export default ScrollToTopButton;
