export function nestedObjectToQueryString(
  obj: Record<string, any>,
  prefix = ""
): string {
  const queryStringParts: string[] = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const fullKey = prefix ? `${prefix}.${key}` : key;

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        // Recursively call the function for nested objects
        queryStringParts.push(nestedObjectToQueryString(value, fullKey));
      } else {
        // Encode key and value and add to the query string parts
        queryStringParts.push(
          `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return queryStringParts.join("&");
}
