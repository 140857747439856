type CurrencyTagProps = { currency: string; fontSize?: "large" | "small" };

export default function CurrencyTag({
  currency,
  fontSize = "large",
}: CurrencyTagProps) {
  return (
    <span
      className="self-stretch"
      style={fontSize == "large" ? {} : { fontSize: "16px" }}
    >
      {currency}
    </span>
  );
}
