import { ReactNode, useCallback, useEffect, useState } from "react";
import { Constants } from "../lib/constant";
import "./flat.dropdown.css";

type FlatDropdownProps = {
  items: Array<{ label: ReactNode; key: string }> | null | undefined;
  children: ReactNode;
};

export default function FlatDropDown({ items, children }: FlatDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="relative topnav-text-link button-padding"
      onMouseLeave={() => setIsOpen(false)}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        onMouseEnter={() => setIsOpen(true)}
      >
        {children}
      </div>
      {isOpen && items && items.length > 0 && (
        <ul
          className="absolute -top-4 -left-4 p-4 shadow"
          style={{
            background: "var(--background-color)",
            zIndex: Constants.ZIndex.DROPDOWNMENU,
          }}
          onMouseLeave={() => setIsOpen(false)}
        >
          <li className="pb-4 button-padding">
            <div
              onClick={() => setIsOpen(!isOpen)}
              className={isOpen ? "li-onhover" : ""}
            >
              {children}
            </div>
          </li>
          {items.map((option) => (
            <li
              key={option.key}
              style={{ lineHeight: "44px" }}
              onClick={() => setIsOpen(!isOpen)}
              className="button-menu-item-padding dropdown-menuitem-content-height"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
