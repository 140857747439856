export const LanguageMappingFromUI2Backend = {
  en: "en", // 在没有导入英语相关的商品信息之前，默认使用俄语
  zh: "ru", // 中文是默认包含的语言，对应数据库中的俄语相关信息
  za: "ru", // 俄语 za是为了避免ru被vercel过滤的问题
} as { [key: string]: string };

export const UICountryFlag = {
  en: "US", // 在没有导入英语相关的商品信息之前，默认使用俄语
  zh: "CN", // 中文是默认包含的语言，默认从后台查询俄语相关信息
  za: "RU", // 俄语-美国
} as { [key: string]: string };

export const UIInternationalDialingCode = {
  // zh: "+86", // 不开放中国电话号码注册
  za: "+7",
} as { [key: string]: string };

export const DefaultUILanguage = "za";
export const DefaultExchangeSourceLanguage = "zh";

// 本地化显示价格信息
export const CurrencyMappingToLocaleInfo = {
  CNY: { locale: "zh-CN", currency: "CNY" },
  RUB: { locale: "ru", currency: "RUB" },
  USD: { locale: "en-US", currency: "USD" },
  "CN¥": { locale: "zh-CN", currency: "CNY" },
  "₽": { locale: "ru", currency: "RUB" },
  $: { locale: "en-US", currency: "USD" },
} as { [key: string]: { locale: string; currency: string } };
