import { createContext } from "react";

export type OfferPricesType = {
  offerData?: offerDataType;
  languageItem?: LanguageType;
  defaultCurrency?: string;
  offerId?: number;
};

export const defaultOfferPricesContextValue = {
  offerData: undefined,
  languageItem: undefined,
  defaultCurrency: undefined,
  offerId: undefined,
} as OfferPricesType;

export const OfferPricesContext = createContext({
  value: defaultOfferPricesContextValue,
  setValue: (v: OfferPricesType) => {},
});
