import localFont from "next/font/local";

export const WixMadeforDisplay = localFont({
  src: [
    {
      path: "../../../public/fonts/WixMadeforDisplay-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../../public/fonts/WixMadeforDisplay-Medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../../public/fonts/WixMadeforDisplay-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../../public/fonts/WixMadeforDisplay-Bold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../../public/fonts/WixMadeforDisplay-ExtraBold.ttf",
      weight: "800",
      style: "normal",
    },
  ],
  variable: "--font-wixmadefordisplay", // 可选，定义一个 CSS 变量
});

export const Unbounded = localFont({
  src: [
    {
      path: "../../../public/fonts/Unbounded-ExtraLight.ttf",
      weight: "200",
      style: "normal",
    },
    {
      path: "../../../public/fonts/Unbounded-Light.ttf",
      weight: "300",
      style: "normal",
    },
    {
      path: "../../../public/fonts/Unbounded-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../../public/fonts/Unbounded-Medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../../public/fonts/Unbounded-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../../public/fonts/Unbounded-Bold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../../public/fonts/Unbounded-ExtraBold.ttf",
      weight: "800",
      style: "normal",
    },
    {
      path: "../../../public/fonts/Unbounded-Black.ttf",
      weight: "900",
      style: "normal",
    },
  ],
  variable: "--font-unbounded", // 可选，定义一个 CSS 变量
});

export const AndtdCssCustomization = {
  colorPrimary: "#FF7A1C",
  colorPrimaryHover: "#FF621F", // hover 状态背景色
  fontSize: 16,
  fontFamily: `${WixMadeforDisplay.style.fontFamily},${Unbounded.style.fontFamily}`,
  colorText: "#313131",
  colorIcon: "#313131",
  boxShadow: "none", // 全局去掉阴影
  borderRadius: 0, // 全局设置为无圆角
};
