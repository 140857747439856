"use client";

import React, { useEffect, useState } from "react";
import { Input, Button, Space, Dropdown, Upload, message } from "antd";
import { SearchOutlined, CameraOutlined } from "@ant-design/icons";
import "./search.bar.css";
import { useTranslation } from "react-i18next";
import {
  generateSearchParams,
  OfferSearchParamsKeyEnum,
} from "../lib/search/client";
import { useRouter, useSearchParams } from "next/navigation";
import { StaticLinks } from "../lib/route/link";
import { useTypedSelector } from "../lib/state/store";
import { searchApi } from "../lib/api/search";
import SearchHistory from "./search.history";
import { offerApi } from "../lib/api/offer";
import { useMediaQueryList } from "../lib/hooks/media.query";
import { checkCacheItemExistInOneDay } from "../lib/util/cache";
import Image from "next/image";

type SearchBarProps = {
  show?: boolean;
  onClose?: () => void;
};
function SearchBar({ show, onClose }: SearchBarProps) {
  const { isMobile } = useMediaQueryList();
  const search = useSearchParams();
  const router = useRouter();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [keyword, setKeyword] = useState<string>("");
  const [focus, setFocus] = useState(false);
  const [visible, setVisible] = useState(false);

  const [topSearches, setTopSearches] = useState<string[]>([]);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);

  const userId = useTypedSelector((state) => state.user.user?.userId);

  const recordSearch = async (searchTerm: string) => {
    if (!checkCacheItemExistInOneDay(`searchTerm:${searchTerm}`)) {
      await searchApi.recordSearch({ searchTerm, userId });
    }
  };

  // 查询按钮，将查询参数通过searchparam传给search页面
  const handleSearch = (value: string, event: any, info?: any) => {
    const searchKey = value.trim();
    if (!searchKey) {
      message.warning(t("plsEnterSearchWords"), 3);
      setKeyword("");
      return;
    }
    if (info?.source === "input") {
      // 先往后台记录查询历史记录
      recordSearch(searchKey);

      const search = generateSearchParams({
        [OfferSearchParamsKeyEnum.subject]: searchKey,
      });
      router.push(`${StaticLinks.Search}?${search}`);
    }
  };

  // 从url search中获取subject值
  useEffect(() => {
    setKeyword(search.get(OfferSearchParamsKeyEnum.subject) || "");
  }, [search]);

  useEffect(() => {
    if (visible) {
      searchApi.getTop10Search().then((res) => {
        setTopSearches(res.data?.map((w) => w.searchTerm) || []);
      });
      searchApi.getRecentSearch(userId).then((res) => {
        setRecentSearches(res.data?.map((w) => w.searchTerm) || []);
      });
    }
  }, [userId, visible]);

  // TODO 图片上传的API调用有问题，目前无法支持自定义图片上传
  const handleImageUpload = (file: any) => {
    const isLt300K = file.size / 1024 < 300;
    if (!isLt300K) {
      message.error(t("image_search_file_size_over_limit"));
      return Upload.LIST_IGNORE;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result;
      base64 = base64.replace("data:image/jpeg;base64,", "");
      offerApi.productImageUpload({ imageBase64: base64 }).then((res) => {
        const imageId = res.data;
        if (imageId) {
          router.push(`${StaticLinks.SearchImageUpload}${imageId}`);
        }
      });
    };
    reader.onerror = (e) => {
      message.error(t("image_search_file_read_fail"));
    };
    reader.readAsDataURL(file);
    return Upload.LIST_IGNORE;
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch(keyword, null, { source: "input" });
    }
  };

  const handleDeleteSearchRecord = async (searchTerm: string) => {
    const res = await searchApi.deleteSearchRecord({ searchTerm, userId });
    if (res.success) {
      searchApi.getRecentSearch(userId).then((res) => {
        setRecentSearches(res.data?.map((w) => w.searchTerm) || []);
      });
    }
  };

  return (
    <div className="search-bar">
      <div className="max-width flex-grow">
        <Dropdown
          dropdownRender={() => (
            <div className="bg-white max-width">
              <SearchHistory
                topSearches={topSearches}
                recentSearches={recentSearches}
                onClick={() => {
                  setVisible(true);
                }}
                handleDeleteSearchRecord={handleDeleteSearchRecord}
              />
            </div>
          )}
          open={visible}
          trigger={["click"]}
          placement="bottomLeft"
          onOpenChange={(show) => {
            setVisible(show);
          }}
          autoAdjustOverflow={false}
        >
          <div className="search-area relative">
            <Input
              placeholder={t("enterSearchWords")}
              prefix={
                <SearchOutlined
                  style={{ fontSize: "20px" }}
                  className="text-dark"
                />
              }
              suffix={
                <Space
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Upload
                    accept="image/jpeg"
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    disabled={focus}
                  >
                    <Button
                      type="text"
                      icon={<CameraOutlined />}
                      title={t("search_by_image")}
                      disabled={focus}
                    />
                  </Upload>
                  {show && (
                    <Button
                      icon={
                        <Image
                          src="/close.svg"
                          alt="close"
                          width="12"
                          height="12"
                        />
                      }
                      className="transparent-style"
                      onClick={onClose}
                    ></Button>
                  )}
                </Space>
              }
              style={{ height: "48px", minWidth: "300px" }}
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
              onKeyUp={handleKeyUp}
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => setFocus(false)}
            />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}

export default SearchBar;
