export namespace Constants {
  export enum ZIndex {
    STICKY = 1000,
    POPUP = 1001,
    DROPDOWNMENU = 900,
    MOSTTOP = 999999,
  }
  export const ItemsPerPage = 12;
  export const MaxInfiniteScrollPageNoAllowed = 200;
  export const NoImageFound = "/no-image-to-show.jpg";
  // 后端数据库中的邮箱验证标志
  export const EMAILVERIFIED = "1";
  // 后端用户类型-商城用户
  export const TOPSLCTDWEBSITEUSER = "01";
}
