"use client";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { OfferDataProxy } from "@/app/lib/offer/offer.data";
import { Price } from "@/app/lib/util/price";
import CurrencyTag from "@/app/components/currency.tag";
import "./style.css";
import { filterInvalidUnitString } from "@/app/lib/util/offer";
import { useMediaQueryListSize } from "@/app/lib/hooks/media.query";

type PriceQuantityProps = {
  offerData: offerDataType;
  languageItem: LanguageType;
  defaultCurrency: string;
  offerId: string;
  align?: "row" | "col";
};

export function PriceQuantity({
  offerData,
  languageItem,
  defaultCurrency,
  offerId,
  align = "col",
}: PriceQuantityProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { exchange, currency } = languageItem;

  const offerDataProxy = useMemo(
    () => new OfferDataProxy(offerData, language, offerId),
    [offerData, language, offerId]
  );
  const priceQuantityRanges = offerDataProxy.getSalePriceQuantityRange();
  const gap = useMediaQueryListSize([
    "gap-[19px]",
    "gap-[36px]",
    "gap-[68px] mb-1",
    "gap-[80px] mb-1",
    "gap-[80px] mb-2",
  ]);
  const flexcss = { col: "flex-col", row: "flex-row " + gap }[align];
  const isHeaderView = align === "row";
  // const imb = useMediaQueryListSize(["", "mb-1", "mb-3", "mb-4", "mb-4"]);
  const imb = "";
  const ip = useMediaQueryListSize([
    "pl-1 pt-1 pb-1 pr-[12px]",
    "pl-[12px] pt-[12px] pb-[12px] pr-[20px]",
    "pl-[12px] pt-[12px] pb-[12px] pr-[20px]",
    "pl-[12px] pt-[12px] pb-[12px] pr-[20px]",
    "pl-[12px] pt-[12px] pb-[12px] pr-[20px]",
  ]);
  const dgap = useMediaQueryListSize([
    "gap-[4px]",
    "gap-[4px]",
    "gap-[4px]",
    "gap-[4px]",
    "gap-[4px]",
  ]);
  return (
    <div>
      <div className={"flex gap-4 justify-start items-start " + flexcss}>
        {priceQuantityRanges.map((price, index) => (
          <div className={`flex flex-nowrap ${dgap}`} key={index}>
            <div className="flex flex-col">
              <div className="flex justify-start" key={index}>
                <span className="title-quantity">
                  {price.quantityRange} {filterInvalidUnitString(price.unit)}
                </span>
              </div>
              <div>
                <div className="flex justify-start items-start">
                  <Typography.Title className="title color-primary">
                    {Price.price2d(price.price * exchange)}{" "}
                    <CurrencyTag currency={currency} />
                  </Typography.Title>
                </div>
              </div>
            </div>
            {!isHeaderView && (
              <div className="self-end i-container relative cursor-default">
                <div className={ip}>
                  <span className={`i ${imb} shadow`}>i</span>
                </div>
                <div className={`hover bg-white ${ip} ${imb} shadow`}>
                  <span className="i i-invert">i</span>
                  <span className="text">{t("price_hint")}</span>
                </div>
              </div>
            )}
            <div className="flex-grow justify-start">
              <div className="flex justify-start" key={index}>
                <span className="title-quantity">&nbsp;</span>
              </div>
              {currency !== defaultCurrency && (
                <div className="flex justify-start items-start">
                  <Typography.Title className="title color-gray">
                    {price.price} <CurrencyTag currency={defaultCurrency} />
                  </Typography.Title>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
