import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import testReducer from "./test.slice";
import categoriesReducer from "./category.slice";
import langReducer from "./lang.slice";
import userReducer from "./user.slice";
import websocketReducer from "./websocket.slice";
import uiReducer from "./ui.slice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  test: testReducer,
  categories: categoriesReducer,
  lang: langReducer,
  user: userReducer,
  websocket: websocketReducer,
  ui: uiReducer,
});

const rootReducerWithHydrate = (state: any, action: any) => {
  if (action.type === "redux_sync_action" && action.key === "topselected") {
    return {
      ...state,
      ...action.payload,
    };
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(
  {
    key: "topselected",
    version: 1,
    storage: storage,
    blacklist: [],
  },
  rootReducerWithHydrate
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// 同步不同标签页的redux store数据
const channel = new BroadcastChannel("redux_sync");
channel.onmessage = (message) => {
  if (message.data.type === "REHYDRATE") {
    // 从消息中获取持久化的状态并更新当前标签页的 store
    store.dispatch({
      type: "redux_sync_action",
      key: "topselected",
      payload: message.data.data,
    });
  }
};

// 同步函数，在需要的时候调用
// 比如：登录 / 登出，其它情况根据需要调用此同步函数
export function syncDataToAllBrowserTabs() {
  const state = store.getState();
  channel.postMessage({
    type: "REHYDRATE",
    data: state,
  });
}
