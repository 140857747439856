type RequestOptions = {
  method: string;
  headers: { [key: string]: string };
  body?: string;
};

type FetchResponse = Response & { json: () => Promise<any> };

class HTTPClient {
  private baseURL: string;
  private token: string | null;
  private fnRedirect: () => void;
  private fn401Redirect: ((redirectUrl: string) => void) | null;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.token = null;
    this.fnRedirect = () => {};
    this.fn401Redirect = null;
  }

  setToken(token: string) {
    console.log("setToken", token);
    this.token = token;
  }

  clearToken() {
    this.token = null;
  }

  setFnRedirect(redirect: () => void) {
    this.fnRedirect = redirect;
  }

  setFn401Redirect(redirect: (redirectUrl: string) => void) {
    this.fn401Redirect = redirect;
  }

  private async request(
    endpoint: string,
    options: RequestOptions
  ): Promise<any> {
    // FIX 修复问题：当打开某一个非主页URL时，request请求发生在setToken之后，这导致页面被跳转
    // 这里添加等待时间100ms，允许setToken先被调用
    if (!this.token) {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(null);
        }, 500);
      });
    }

    // 如果有令牌，将其添加到请求头部
    if (this.token) {
      options.headers["Authorization"] = `Bearer ${this.token}`;
    }

    try {
      const response: FetchResponse = await fetch(
        `${this.baseURL}${endpoint}`,
        {
          ...{
            ...options,
            headers: {
              ...options.headers,
              "x-api-key": "5c6f8ff2-7897-11ef-be43-a029423fbweb",
            },
          },
          next: { tags: ["collection"] },
          credentials: "include",
        }
      );

      // 检查响应状态码
      if (!response.ok) {
        const errorDetails = await response.json();

        // 检查token失效并重新登录
        if (errorDetails.code === 401) {
          console.trace("stack trace for 401 code");
          setTimeout(() => {
            this.fn401Redirect &&
              this.fn401Redirect(window.location.href.replace(origin, ""));
          }, 100);
        }

        throw new Error(
          `HTTP error! Status: ${response.status}, Details: ${JSON.stringify(
            errorDetails
          )}`
        );
      }

      // 尝试解析响应体为 JSON
      try {
        const data = await response.json();
        // 如果返回401 token失效错误，则进行跳转
        if ((data as any).code === 401) {
          this.fnRedirect();
        } else return data;
      } catch (error) {
        // 如果解析 JSON 失败，返回原始响应
        return response;
      }
    } catch (error) {
      // 捕获所有错误并抛出，以便在调用时处理
      console.error("Request failed:", error);
      throw error;
    }
  }

  async get(endpoint: string, params?: { [key: string]: any }): Promise<any> {
    // 如果有查询参数，将其转换为查询字符串
    const url = new URL(`${this.baseURL}${endpoint}`);
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }
    return await this.request(url.toString().replace(this.baseURL, ""), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async post(endpoint: string, data: any, isFormData = false): Promise<any> {
    return await this.request(endpoint, {
      method: "POST",
      headers: isFormData
        ? {}
        : {
            "Content-Type": "application/json",
          },
      body: isFormData ? data : JSON.stringify(data),
    });
  }

  async put(endpoint: string, data: any): Promise<any> {
    return await this.request(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  async delete(endpoint: string): Promise<any> {
    return await this.request(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export const apiClient = new HTTPClient(
  process.env.NEXT_PUBLIC_API_BASE_URL || ""
);
