import { useTypedSelector } from "../lib/state/store";
import Image from "next/image";
import { DefaultExchangeSourceLanguage } from "../lib/language/lang.config";
import FlatDropDown from "./flat.dropdown";
import {
  useMediaQueryList,
  useMediaQueryListSize,
} from "../lib/hooks/media.query";
import { Dropdown } from "antd";

function CurrencyExchange() {
  const { isNonDesktop } = useMediaQueryList();
  const languages = useTypedSelector((state) => state.lang).languages;
  const defaultLanguage = languages.find(
    (w) => w.lang === DefaultExchangeSourceLanguage
  );

  const items = languages
    .filter((l) => l.status === "1")
    .map(({ currency, exchange, lang }) => ({
      key: lang,
      label: (
        <div className="flex flex-nowrap item-center topnav-text-link dropdown-menuitem-content-height">
          1{defaultLanguage?.currency} = {exchange}
          {currency}
        </div>
      ),
    }))
    .filter((w) => w.key !== DefaultExchangeSourceLanguage);

  const ml = useMediaQueryListSize([
    "ml-[10px]",
    "ml-[7px]",
    "ml-[7px]",
    "ml-[7px]",
    "ml-[7px]",
  ]);

  return !isNonDesktop ? (
    <FlatDropDown items={items}>
      <a
        onClick={(e) => e.preventDefault()}
        className="flex items-center topnav-text-link justify-between"
      >
        ₽
        <span className={`flex items-center ${ml}`}>
          <Image
            src="/arrow-down.png"
            alt="arrow-down"
            width="12"
            height="12"
            className="arrow-img"
          />
        </span>
      </a>
    </FlatDropDown>
  ) : (
    <Dropdown menu={{ items }}>
      <a
        href="#"
        className="flex items-center footer-nav-text-link button-padding"
      >
        ₽
        <span className={`flex items-center ${ml}`}>
          <Image
            src="/arrow-down.png"
            alt="arrow-down"
            width="12"
            height="12"
            className="arrow-img"
          />
        </span>
      </a>
    </Dropdown>
  );
}

export default CurrencyExchange;
