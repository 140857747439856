import { apiClient } from "../util/http";

class Search {
  private searchUrl: string = "/web";

  recordSearch(
    searchTerm: RecordSearchType
  ): Promise<InterfaceBasicResponseDataType> {
    try {
      return apiClient.post(this.searchUrl + "/record_search", searchTerm);
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }

  deleteSearchRecord(
    searchTerm: RecordSearchType
  ): Promise<InterfaceBasicResponseDataType> {
    try {
      return apiClient.post(
        this.searchUrl + "/delete_search_record",
        searchTerm
      );
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }

  getTop10Search(): Promise<
    InterfaceBasicResponseDataType & {
      data: TopSearchRecordType[];
    }
  > {
    try {
      return apiClient.get(this.searchUrl + "/search_stat_top_10");
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }

  getRecentSearch(userId?: number): Promise<
    InterfaceBasicResponseDataType & {
      data: SearchRecordType[];
    }
  > {
    try {
      const param = "?userId=" + (userId || "");
      return apiClient.get(this.searchUrl + "/search_recent" + param);
    } catch (e) {
      console.log(e);
      return new Promise({} as any);
    }
  }
}

export const searchApi = new Search();
