"use client";

import "./header.css";
import "./footer.css";
import Link from "next/link";
import { Badge } from "antd";

import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./language.switcher";
import CurrencyExchange from "./currency.exchange";
import { StaticLinks } from "../lib/route/link";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { syncDataToAllBrowserTabs, useTypedSelector } from "../lib/state/store";
import { userApi } from "../lib/api/user";
import { useDispatch } from "react-redux";
import { setToken, setUserProfile } from "../lib/state/user.slice";
import { usePathname, useRouter } from "next/navigation";
import { apiClient } from "../lib/util/http";
import { useWebSocketMessages } from "../lib/ws/useWebsocketMessages";
import { WebSocketContext } from "../lib/ws/websocket.context";
import Image from "next/image";
import SearchBar from "./search.bar";
import FlatDropDown from "./flat.dropdown";
import { Constants } from "../lib/constant";
import { setShowCategoryMenu } from "../lib/state/ui.slice";
import {
  useMediaQueryList,
  useMediaQueryListSize,
} from "../lib/hooks/media.query";

export function FooterNav() {
  const { isNonDesktop } = useMediaQueryList();
  const router = useRouter();
  const dispatch = useDispatch();
  const pathname = usePathname();
  const { t } = useTranslation("translation");
  const user = useTypedSelector((state) => state.user);
  const [cartNumber, setCartNumber] = useState(0);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const { message } = useWebSocketMessages();
  const { closeWs } = useContext(WebSocketContext);

  useEffect(() => {
    const item = message;
    if (item?.cart_info && Array.isArray(item?.cart_info)) {
      const n = item.cart_info.length;
      setCartNumber(n);
    } else {
      setCartNumber(0);
    }
  }, [message]);

  // 退出
  const logOut = useCallback(async () => {
    await userApi.logout();
    dispatch(setUserProfile(null));
    dispatch(setToken({ token: "" }));
    apiClient.setToken("");
    syncDataToAllBrowserTabs();
    // 关闭websocket连接
    closeWs();
    router.push(StaticLinks.Login);
  }, [closeWs, dispatch, router]);

  const userItems = useMemo(() => {
    return {
      loggedIn: [
        {
          key: "shoppingcart",
          label: (
            <Link href={StaticLinks.ShoppingCart} className="a-nohover">
              <div className="flex flex-nowrap item-center first-letter:uppercase">
                {t("shoppingCart")}
              </div>
            </Link>
          ),
        },
        {
          key: "myorders",
          label: (
            <Link href={StaticLinks.Order} className="a-nohover">
              <div className="flex flex-nowrap item-center first-letter:uppercase">
                {t("my_orders")}
              </div>
            </Link>
          ),
        },
        {
          key: "setting",
          label: (
            <Link href={StaticLinks.AccountSetting} className="a-nohover">
              <div className="flex flex-nowrap item-center first-letter:uppercase">
                {t("user.account.setting")}
              </div>
            </Link>
          ),
        },
        {
          key: "logout",
          label: (
            <Link
              href=""
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                logOut();
              }}
              className="a-nohover"
            >
              <div className="flex flex-nowrap item-center first-letter:uppercase">
                {t("logout")}
              </div>
            </Link>
          ),
        },
      ],
      loggedout: [
        {
          key: "Register",
          label: (
            <Link href={StaticLinks.Register} className="a-nohover">
              <div className="flex flex-nowrap item-center first-letter:uppercase">
                {t("register")}
              </div>
            </Link>
          ),
        },
      ],
    };
  }, [logOut, t]);

  const onOpenCategories = () => {
    dispatch(setShowCategoryMenu(true));
  };

  const iconSize = useMediaQueryListSize([20, 20, 20, 36, 36]);
  const flexDirection = useMediaQueryListSize([
    "flex-col",
    "flex-row",
    "flex-row",
    "flex-col",
    "flex-col",
  ]);

  return (
    <div>
      {showSearchBar && (
        <div
          className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-white flex justify-center items-start"
          style={{ zIndex: Constants.ZIndex.POPUP + 1 }}
        >
          <SearchBar show={true} onClose={() => setShowSearchBar(false)} />
        </div>
      )}
      <div
        className="fixed bottom-0 left-0 w-[100vw] flex flex-row justify-around items-center py-2 px-3 bg-white"
        style={{ zIndex: Constants.ZIndex.POPUP }}
      >
        <Link
          href="#"
          className="footer-nav-text-link flex-shrink-0"
          onClick={onOpenCategories}
        >
          <div
            className={`flex ${flexDirection} items-center justify-end gap-1`}
          >
            <Image
              src="/menu.svg"
              width={iconSize}
              height={iconSize}
              alt="hot"
            ></Image>
            <span>{t("categories")}</span>
          </div>
        </Link>
        <Link
          href="#"
          onClick={() => setShowSearchBar(!showSearchBar)}
          className="footer-nav-text-link flex-shrink-0"
        >
          <div
            className={`flex ${flexDirection} items-center justify-end gap-1`}
          >
            <Image
              src={showSearchBar ? "/search-primary.svg" : "/search.svg"}
              width={iconSize}
              height={iconSize}
              alt="hot"
              style={{ padding: "2px" }}
            ></Image>
            <span>{t("search")}</span>
          </div>
        </Link>
        <Link
          href={StaticLinks.TopRank}
          className="footer-nav-text-link flex-shrink-0"
        >
          <div
            className={`flex ${flexDirection} items-center justify-end gap-1`}
          >
            <Image
              src="/fire.svg"
              width={iconSize}
              height={iconSize}
              alt="hot"
            ></Image>
            <span>{t("hot")}</span>
          </div>
        </Link>
        {!isNonDesktop && (
          <FlatDropDown
            items={user?.user ? userItems.loggedIn : userItems.loggedout}
          >
            <Link
              href={
                user?.user
                  ? isNonDesktop
                    ? StaticLinks.AccountSetting
                    : "#"
                  : `${
                      pathname.includes(StaticLinks.Login)
                        ? StaticLinks.Login
                        : StaticLinks.LoginWithRedirect + pathname
                    }`
              }
              className="footer-nav-text-link flex-shrink-0"
            >
              <div className="flex items-start">
                <div
                  className={`flex ${flexDirection} items-center justify-end flex-shrink-0 gap-1`}
                >
                  <Image
                    src="/user.svg"
                    width={iconSize}
                    height={iconSize}
                    alt="login"
                    style={{ padding: "2px" }}
                  ></Image>
                  <span>{user.user?.userName || t("login")}</span>
                </div>
              </div>
            </Link>
          </FlatDropDown>
        )}
        {isNonDesktop && (
          <Link
            href={
              user?.user
                ? isNonDesktop
                  ? StaticLinks.AccountSetting
                  : "#"
                : `${
                    pathname.includes(StaticLinks.Login)
                      ? StaticLinks.Login
                      : StaticLinks.LoginWithRedirect + pathname
                  }`
            }
            className="footer-nav-text-link flex-shrink-0"
          >
            <div className="flex items-start">
              <div
                className={`flex ${flexDirection} items-center justify-end flex-shrink-0 gap-1`}
              >
                <Image
                  src="/user.svg"
                  width={iconSize}
                  height={iconSize}
                  alt="login"
                  style={{ padding: "2px" }}
                ></Image>
                <span>{user.user?.userName || t("login")}</span>
              </div>
            </div>
          </Link>
        )}

        <Link
          href={`${StaticLinks.ShoppingCart}`}
          title={t("shoppingCart")}
          className="flex items-center footer-nav-text-link flex-shrink-0"
        >
          <div
            className={`flex ${flexDirection} items-center justify-end gap-1`}
          >
            <Badge count={cartNumber} overflowCount={999} size="default">
              <Image
                src="/cart.svg"
                width={iconSize}
                height={iconSize}
                alt="cart"
              ></Image>
            </Badge>
            {t("shoppingCart")}
          </div>
        </Link>
      </div>
    </div>
  );
}
