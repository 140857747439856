import React from "react";
import { Result, Button } from "antd";
import { useTranslation } from "react-i18next";

interface ErrorBoundaryState {
  hasError: boolean;
  errorInfo: string | null;
}

class ErrorBoundary extends React.Component<
  { children: any },
  ErrorBoundaryState
> {
  constructor(props: { children: any }) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error) {
    // 更新 state 以触发重新渲染并显示错误信息
    return { hasError: true, errorInfo: error.message };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // 可以将错误信息上报给日志服务
    console.error("Error:", error, errorInfo);
  }

  handleReload = () => {
    // 点击按钮后刷新页面或执行其他操作
    this.setState({ hasError: false, errorInfo: null });
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <ResultComp
          subTitle={this.state.errorInfo || ""}
          handleClick={this.handleReload}
        />
      );
    }

    // 渲染子组件
    return this.props.children;
  }
}

export default ErrorBoundary;

type ResultCompProps = {
  subTitle: string;
  handleClick: () => void;
};
function ResultComp({ subTitle, handleClick }: ResultCompProps) {
  const { t } = useTranslation();
  return (
    <Result
      status="error"
      title={t("something_went_wrong")}
      subTitle={subTitle || t("unknown_error_occured")}
      extra={
        <Button type="primary" onClick={handleClick}>
          {t("reload")}
        </Button>
      }
    />
  );
}
