const uniqueIdentifier = "unique-id-cin-thd-usu-aua-001";

function checkCacheItemExistInTime(value: any, seconds: number = 24 * 60 * 60) {
  try {
    const key = JSON.stringify(value);
    const storedItem = localStorage.getItem(uniqueIdentifier);
    if (storedItem) {
      const storedData = JSON.parse(storedItem);
      let ret = false;
      if (storedData[key]) {
        ret = true;
      } else {
        storedData[key] = { data: value, time: new Date() };
      }
      // 清除旧的记录
      clearCacheItemsByCondition(storedData, seconds);
      localStorage.setItem(uniqueIdentifier, JSON.stringify(storedData));
      return ret;
    } else {
      const newData = {} as { [key: string]: any };
      newData[key] = { data: value, time: new Date() };
      localStorage.setItem(uniqueIdentifier, JSON.stringify(newData));
      return false;
    }
  } catch (error) {
    return false;
  }
}

// 删除带有uniqueIdentifier的localstorage项
function clearCacheItemsByCondition(
  storedData: { key: string; [key: string]: any },
  seconds: number
) {
  const currentTime = new Date();
  Object.keys(storedData).forEach((key) => {
    const storedTime = new Date(storedData[key].time);
    const timeDifference = currentTime.getTime() - storedTime.getTime();
    if (timeDifference > seconds * 1000) {
      delete storedData[key];
    }
  });
  localStorage.setItem(uniqueIdentifier, JSON.stringify(storedData));
}

export function checkCacheItemExistInOneDay(value: any) {
  return checkCacheItemExistInTime(value);
}
