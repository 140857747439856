import { offerApi } from "../api/offer";
import { TagTailwindCssColors } from "../css/tag.colors";
import { DefaultExchangeSourceLanguage } from "../language/lang.config";
import { Price } from "../util/price";

export function getOfferTags(
  props: OfferExtType,
  colorType: "light" | "dark" = "light"
) {
  const data = [
    props.isOnePsale,
    props.isOnePsaleFreePostage,
    // props.isQqyx,
    props.isSupportMix,
    props.noReason7DReturn,
    // props.yx1688,
  ];
  const cssClassColors = TagTailwindCssColors()[colorType];
  // 注意！！ keys的顺序和data的顺序必须保持一致
  const keys = [
    "is_one_psale",
    "is_one_psale_free_postage",
    // "is_qqyx",
    "is_support_mix",
    "no_reason_7_d_return",
    // "yx_1688",
  ];
  return keys.map((key, index) => ({
    key,
    className: cssClassColors[index],
    value: data[index],
    show: !!data[index],
  }));
}

export function getOfferWithValidOfferData(
  offerWithTrans: OfferExtType[],
  lang?: string
) {
  return offerWithTrans.find(
    (w) => w.offerData && (lang ? w.lang === lang : true)
  );
}

export function getOfferKeyValueByUILanguage(
  offer: { [key: string]: any },
  key: string,
  uiLang: string,
  keyTrans?: string
) {
  // 多语言切换时，返回offer的subject语言，默认当lang是中文时，返回subject，
  // 其它情况返回已经通过ApiDataHelper.joinTwoTables(offers[0], i18n.language)转换的目标语言描述
  // 默认每一个offerData中都已经包含了中文信息以及对应的语言翻译信息
  return uiLang === DefaultExchangeSourceLanguage
    ? offer[key]
    : offer[keyTrans || `${key}Trans`];
}

export function exportOfferFromOfferDict(offers: {
  [key: string]: OfferExtType[];
}) {
  return Object.getOwnPropertyNames(offers).map((id) => offers[id]);
}

export function getOfferImages(props?: OfferExtType) {
  if (!props) return [];

  const offerData =
    typeof props.offerData === "string"
      ? JSON.parse(props.offerData)
      : props.offerData;
  return offerData?.productImage?.images || [];
}

// 所有和OfferData相关的操作都放在这里
export class OfferDataProxy {
  private _data = {} as offerDataType;
  private _lang = "";
  constructor(
    offerData: offerDataType,
    uiLanguage: string,
    offerId?: number | string
  ) {
    if (!offerData) {
      !!offerId &&
        setTimeout(() => {
          offerApi.syncOffer(`${offerId}`);
        }, 10);
      throw new Error(
        `offerData can not be undefined. (offerId:${offerId || ""})`
      );
    }
    this._data = offerData;
    if (typeof this._data === "string") {
      // 如果是字符串格式，转为json
      this._data = JSON.parse(this._data);
    }
    this._lang = uiLanguage;
    // console.log(this._data);
  }
  getOfferId() {
    return this._data.offerId;
  }
  getChannelPriceRange() {
    const list = this._data["channelPrice"]["channelSkuPriceList"].map((w) =>
      parseFloat(w.currentPrice)
    );
    const max = Price.price2d(Math.max(...list));
    const min = Price.price2d(Math.min(...list));
    return { max, min, isOnePrice: max === min };
  }
  getSalePriceQuantityRange() {
    const list = this._data["productSaleInfo"]["priceRangeList"].map((w) => ({
      price: parseFloat(w.price),
      startQuantity: w.startQuantity,
      unit: this.getProductSaleUnit(this._lang),
    }));
    const listPriceQuantityRange = calculateQuantityRanges(list);
    return listPriceQuantityRange;
  }
  getSalePriceByQuantity(quantity: number) {
    // 根据数量获取销售价格
    const list = this._data["productSaleInfo"]["priceRangeList"].map((w) => ({
      price: parseFloat(w.price),
      startQuantity: w.startQuantity,
      unit: this.getProductSaleUnit(this._lang),
    }));
    const listPriceQuantityRange = calculateQuantityRanges(list);
    const descList = listPriceQuantityRange.sort((a, b) =>
      a.startQuantity < b.startQuantity ? 1 : -1
    );
    let ret = descList.find((w) => quantity >= w.startQuantity)?.price;
    if (ret === undefined) {
      ret = descList[descList.length - 1].price;
    }
    return ret;
  }
  getSubject() {
    return getOfferKeyValueByUILanguage(this._data, "subject", this._lang);
  }
  getImages() {
    return this._data.productImage.images;
  }
  getSkuList() {
    const list = this._data["productSkuInfos"];
    return list;
  }
  getSkuAttributeIds() {
    // 根据第一个sku获取所有商品属性
    const ids = this._data["productSkuInfos"]?.[0].skuAttributes.map(
      (w) => w.attributeId
    );
    return ids;
  }
  getSkuInfo(lang: string) {
    // 获取所有的AttributeName以及对应的value选项
    const attributeNamesValues = {} as { [key: string]: Set<string> };
    const skuInfo = {} as {
      [key: string]: {
        amount: number;
        price: number;
        skuId: number;
        specId: string;
      };
    };
    this._data["productSkuInfos"]?.forEach((product) => {
      let skuInfoKey = [] as string[];
      product.skuAttributes.forEach((attribute) => {
        const name = getOfferKeyValueByUILanguage(
          attribute,
          "attributeName",
          lang
        ) as string;
        const value = getOfferKeyValueByUILanguage(
          attribute,
          "value",
          lang
        ) as string;
        const skuImageUrl = attribute.skuImageUrl;

        // 这里的关键字是所有attributes的value字符串连接
        skuInfoKey.push(value);
        if (!attributeNamesValues[name]) {
          attributeNamesValues[name] = new Set();
        }
        attributeNamesValues[name].add(JSON.stringify({ value, skuImageUrl }));
      });

      const amount = product.amountOnSale;
      const price =
        product.jxhyPrice ||
        parseFloat(`${product.price}`) ||
        product.consignPrice;
      const skuId = product.skuId;
      const specId = product.specId;

      // 这里避免attributeNamesValues排列对key产生影响
      skuInfo[skuInfoKey.slice().sort().join()] = {
        amount,
        price,
        skuId,
        specId,
      };
    });
    return {
      attributeNamesValues: Object.keys(attributeNamesValues)
        .map((w) => ({
          name: w,
          values: Array.from(attributeNamesValues[w]).map((x) =>
            JSON.parse(x)
          ) as {
            value: string;
            skuImageUrl: string;
          }[],
        }))
        // 按values的数量逆序排列
        .sort((a, b) => (a.values.length > b.values.length ? -1 : 1)),

      // 根据attributeNamesValues中的多个value组合可以找到skuInfo
      skuInfo: skuInfo,
    };
  }

  getSkuInfoBySpecId(specId: string) {
    return this._data["productSkuInfos"].find((w) => `${w.specId}` === specId);
  }

  getProductSaleUnit(uiLang: string) {
    // 这里不要用backendLang，需要使用uiLang
    const unitInfo = this._data["productSaleInfo"]["unitInfo"];
    const unit = getOfferKeyValueByUILanguage(
      unitInfo,
      "unit",
      uiLang,
      "transUnit"
    );
    return unit;
  }

  getSkuListByAttributeValue(value: string, lang: string) {
    const skus = this._data["productSkuInfos"];
    const filteredSkuList = skus
      .filter((sku) =>
        sku.skuAttributes.find(
          (w) => getOfferKeyValueByUILanguage(w, "value", lang) === value
        )
      )
      .map((w) => w.skuId);
    return filteredSkuList;
  }

  getStartQuantity() {
    return this._data.minOrderQuantity;
  }
  getDescription() {
    return this._data.description;
  }
  getMainVideo() {
    return this._data.mainVideo;
  }
  getVideoWhiteImage() {
    return this._data.productImage.whiteImage;
  }
  getSpecDescription(specId: string, lang: string) {
    const skuAttrs = this._data.productSkuInfos?.find(
      (w) => w.specId == specId
    )?.skuAttributes;
    // 无规格商品
    if (!skuAttrs) return "";

    if (Array.isArray(skuAttrs)) {
      return skuAttrs.reduce(
        (s, c) => s + " " + getOfferKeyValueByUILanguage(c, "value", lang),
        ""
      );
    }
    return getOfferKeyValueByUILanguage(skuAttrs, "value", lang);
  }
  getOfferAttributes(lang: string) {
    const list = this._data.productAttribute.map((w) => ({
      key: w.value,
      label: getOfferKeyValueByUILanguage(w, "attributeName", lang),
      children: getOfferKeyValueByUILanguage(w, "value", lang),
    }));

    const attributes = {} as {
      [key: string]: {
        key: string;
        label: string;
        children: string[];
      };
    };

    list.forEach((w) => {
      if (!attributes[w.label]) {
        attributes[w.label] = { ...w, children: [w.children] };
      } else {
        // 合并label相同的value到一个字符串中
        attributes[w.label].children = attributes[w.label].children.concat([
          w.children,
        ]);
      }
    });

    return Object.keys(attributes).map((w) => ({
      ...attributes[w],
      children: attributes[w].children.join(", "),
    }));
  }
  getOfferImages() {
    return this._data.productImage?.images || [];
  }
  getThirdCategoryId() {
    return this._data.thirdCategoryId;
  }
  getSecondCategoryId() {
    return this._data.secondCategoryId;
  }
  getTopCategoryId() {
    return this._data.topCategoryId;
  }
  getAmountOnSaleForNoSpecProduct() {
    return this._data.productSaleInfo.amountOnSale;
  }
}

// 根据_data["productSaleInfo"]["priceRangeList"]的单点数值，返回数量范围
function calculateQuantityRanges(
  pricingData: { price: number; startQuantity: number; unit: string }[]
) {
  const result = pricingData.map((item, index, arr) => {
    let range;
    if (index < arr.length - 1) {
      // For all elements except the last one
      range = `${item.startQuantity} ~ ${arr[index + 1].startQuantity - 1}`;
    } else {
      // For the last element
      range = `≥ ${item.startQuantity}`;
    }
    return {
      ...item,
      quantityRange: range,
    };
  });
  return result;
}

export function isPartnerOffer(offerId: number) {
  return offerId > 9000000000000000;
}
